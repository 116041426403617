import React from 'react';

const ApplicantRoot = React.lazy(() => import('@application/applicant'));
const BackofficeRoot = React.lazy(() => import('@application/backoffice'));

const App: React.FC = () => {
  const ModuleRoot = window.FF_MODULE === 'backoffice' ? BackofficeRoot : ApplicantRoot;

  return <ModuleRoot />;
};

export default App;
