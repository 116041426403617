import { theme as defaultTheme } from '@ff/design-system';

const breakpointValues = {
  sm: 1024,
  md: 1280,
  lg: 1440,
};

const breakpointMedia = {
  sm: `@media only screen and (min-width: ${breakpointValues.sm}px)`,
  md: `@media only screen and (min-width: ${breakpointValues.md}px)`,
  lg: `@media only screen and (min-width: ${breakpointValues.lg}px)`,
};

const dividers = {
  bottomDefault: `inset 0px -1px 0px rgba(224, 228, 232, 0.6)`,
  bottomAlt: `inset 0px -1px 0px rgba(196, 202, 207, 0.6)`,
  rightDefault: `inset -1px 0px 0px rgba(224, 228, 232, 0.6)`,
  rightAlt: `inset -1px 0px 0px rgba(196, 202, 207, 0.6)`,
  topDefault: `inset 0px 1px 0px rgba(224, 228, 232, 0.6`,
  topAlt: `inset 0px 1px 0px rgba(196, 202, 207, 0.6)`,
  leftAlt: `inset 1px 0px 0px rgba(196, 202, 207, 0.6)`,
};

const theme = {
  breakpoints: {
    value: breakpointValues,
    media: breakpointMedia,
  },
  dividers,
  ...defaultTheme,
};

export type Theme = typeof theme;

export default theme;
