import { GlobalStyle } from '@ff/design-system';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import App from '@application/App';
import { theme } from '@shared/theme';

import '@ff/design-system/public/assets/fonts/inter.css';
import '@images/favicon.ico';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
